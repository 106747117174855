import { useContext } from 'react';
import ConfigurationContext from '../Contexts/ConfigurationContext';

export const DateToLocaleString = (date, options) => {

    const configurationContext = useContext(ConfigurationContext);

    if (date == null) {
        return null;
    }

    if (configurationContext.configurationData.length > 0) {
        let locale = configurationContext.configurationData.find(element => element.name === "Locale");

        if (locale) {
            return new Date(date).toLocaleString(locale.value, options);
        } else {
            console.warn("Locale not found. Add a configuration entry for 'Locale' in Configuration Data with a valid locale, e.g. en-GB");
            return new Date(date).toISOString();
        }
    }
}

export const longDateFormat = {
    day: "2-digit",
    month: "short",
    year: "numeric"
};

export const longDateAndTimeFormat = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
};