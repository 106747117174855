import { useTranslation } from 'react-i18next';
import DataTable from '../../../DataTable/datatable.component';
import DateColumn from '../../../DataTable/datatable.datecolumn';

function QIRSection({ qirs }) {

    const { t } = useTranslation();

    const tableColumns = [
        {
            Header: t('LotDetails.QIRs.IncidentId'),
            accessor: 'incidentId',
            filter: 'includesString'
        },
        DateColumn({ header: t('LotDetails.QIRs.IncidentDate'), accessor: 'incidentDate' }),
        DateColumn({ header: t('LotDetails.QIRs.ReportedDate'), accessor: 'reportedDate' }),
        DateColumn({ header: t('LotDetails.QIRs.EndDate'), accessor: 'endDate' }),
        {
            Header: t('LotDetails.QIRs.Statement'),
            accessor: 'statement',
            filter: 'includesString'
        },
        {
            Header: t('LotDetails.QIRs.ReportedBy'),
            accessor: 'reportedBy',
            filter: 'includesString'
        }
    ];

    let table = DataTable({ tableColumns: tableColumns, tableData: qirs, initialState: {} });

    return qirs.length > 0 ? table : "No data";
}

export default QIRSection;