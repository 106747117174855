import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import { Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter, searchIcon }) {

    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200);

    return (
        <>
            {searchIcon &&
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                    <Form.Control size="sm" id="globalFilter" value={value || ""}
                        title={`Search ${count} records...`}
                        onChange={e => {
                            setValue(e.target.value);
                            onChange(e.target.value);
                        }} />
                </InputGroup>
            }
            {!searchIcon &&
                <Form.Control size="sm" id="globalFilter" value={value || ""}
                    title={`Search ${count} records...`}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }} />
            }
        </>
    )
}

export default GlobalFilter;