import { useState, useEffect } from 'react';
import { fetchDataAuthenticated, correlationId } from '../../Utilities/HttpUtils';
import useAlert from '../../Alerts/useAlert';

function useLotDetails(lotNumber) {

    const { setAlert } = useAlert();

    const [lotDetail, setLotDetail] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (lotNumber) {
            getLotDetail();
        }
    }, [lotNumber]);

    async function getLotDetail(revisionId) {
        let url = `api/v1/lot/${lotNumber}/detail`;

        if (revisionId) {
            url += `?revisionId=${revisionId}`;
        }

        await fetchDataAuthenticated(url)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 404) {
                    throw new Error(`Lot ${lotNumber} not found.`);
                }
                throw new Error(`Failed to get lot detail [${correlationId}]`);
            })
            .then(data => {
                setLotDetail(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    async function checkForLotChanges(lotNumber, revisionId, status, processingStatus, noOfComments) {
        var lotHasChanged = false;
        let url = `api/v1/lot/${lotNumber}/changes?revisionId=${revisionId}&status=${status}&processingStatus=${processingStatus}&numberOfComments=${noOfComments}`;

        await fetchDataAuthenticated(url)
            .then(async response => {
                if (response.ok) {
                    lotHasChanged = await response.json();
                    return;
                }
            })

        return lotHasChanged;
    }

    return { lotDetail, getLotDetail, setLotDetail, loading, setLoading, checkForLotChanges }
}

export default useLotDetails;