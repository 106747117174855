import React, { useState } from 'react';
import CommonConfigDialog from './CommonConfigDialog';
import { DialogInput, RequiredDialogInput, DialogSelect, RequiredDialogSelect, DialogCheckbox } from '../../DialogInputs';
import { handleInputChange } from '../../Utilities/DialogUtils';
import { useQualities } from '../../Utilities/Hooks';
import { fetchDataAuthenticated } from '../../Utilities/HttpUtils';

function LabResultConfigEditDialog({ dialogData, setDialogData, showDialog, closeDialog, businessUnits }) {

    const [fieldErrors, setFieldErrors] = useState({});
    const qualities = useQualities(false, dialogData.businessUnitId);

    const saveLabResultConfig = (labResultConfig) => {

        let promise = new Promise((resolve, reject) => {
            let url = `api/v1/labresultconfig`;
            if (labResultConfig.labResultConfigId != null) {
                url += `/${labResultConfig.labResultConfigId}`
            }

            fetchDataAuthenticated(url, "POST", JSON.stringify(labResultConfig))
                .then(response => response.json())
                .then(data => {
                    if (data.errors !== null && data.errors !== undefined) {
                        reject(data);
                    }
                    resolve(data);
                })
                .catch(error => {
                    alert("There was a problem! " + error);
                });

        });

        return promise;
    };

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }
    
    return (
        <CommonConfigDialog title={"Lab Result Config"} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveLabResultConfig} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors}>
            <RequiredDialogSelect controlId="formLRCBusinessUnit" title="Business Unit" name="businessUnitId" value={dialogData.businessUnitId} error={fieldErrors.businessUnitId} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {businessUnits.map((bu) => <option value={bu.businessUnitId} key={bu.businessUnitId}>{bu.name}</option>)}
            </RequiredDialogSelect>
            <RequiredDialogSelect controlId="formLRCQuality" title="Quality" name="qualityId" value={dialogData.qualityId} error={fieldErrors.qualityId} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                <option value="-1" key={-1} >None</option>
                {qualities.map((q) => <option value={q.qualityId} key={q.qualityId}>{q.name}</option>)}
            </RequiredDialogSelect>
            <DialogInput controlId="formLRCDescription" title="Description" name="description" value={dialogData.description} error={fieldErrors.description} onChange={onChange} />
            <RequiredDialogInput controlId="formLRCSamplingPoint" title="Sampling Point" name="samplingPoint" value={dialogData.samplingPoint} error={fieldErrors.samplingPoint} onChange={onChange} />
            <DialogInput controlId="formLRCUnitArea" title="Unit Area" name="unitArea" value={dialogData.unitArea} error={fieldErrors.unitArea} onChange={onChange} />
            <DialogInput controlId="formLRCSampleName" title="Sample Name" name="sampleName" value={dialogData.sampleName} error={fieldErrors.sampleName} onChange={onChange} />
            <RequiredDialogInput controlId="formLRCAnalysis" title="Analysis" name="analysis" value={dialogData.analysis} error={fieldErrors.analysis} onChange={onChange} />
            <RequiredDialogInput controlId="formLRCComponent" title="Component" name="component" value={dialogData.component} error={fieldErrors.component} onChange={onChange} />
            <DialogInput controlId="formLRCLabResultType" title="Lab Result Type" name="labResultType" value={dialogData.labResultType} error={fieldErrors.labResultType} onChange={onChange} />
            <DialogCheckbox disabled={dialogData.labResultConfigId === null} controlId="formLRCIsDeleted" title="Deleted?" name="isDeleted" checked={dialogData.isDeleted} error={fieldErrors.isDeleted} onChange={onChange} />
        </CommonConfigDialog>
    );
}

export default LabResultConfigEditDialog;