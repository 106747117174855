import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DraggableModalDialog } from '../DraggableModalDialog';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

function SupportDlg(props) {

    SupportDlg.propTypes = {
        isOpen: PropTypes.bool.isRequired,
        toggleModal: PropTypes.func.isRequired
    }

    const { t } = useTranslation();

    return (
        <Modal dialogAs={DraggableModalDialog} show={props.isOpen} onHide={props.toggleModal} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("Application.Title")} {t("Dialogs.Support.Title")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-start">
                    <FontAwesomeIcon icon={faInfoCircle} color="#4189C7" size="xl" className="me-3 mt-2" />
                    <p>{t("Dialogs.Support.WelcomeText")}</p>
                </div>

                <p>
                    <strong>{t("Dialogs.Support.SupportTitle")}</strong>
                </p>
                <p>{t("Dialogs.Support.SupportTeam")}<br />{t("Dialogs.Support.SupportProvider")}</p>
                <p>{t("Dialogs.Support.SupportText")} <a href={t("Dialogs.Support.SupportLink")} target="blank">{t("Dialogs.Support.SupportLink")}</a></p>
            </Modal.Body>
            <Modal.Footer>
                <Button color="primary" onClick={props.toggleModal}>{t("Application.Controls.Ok")}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SupportDlg;