import { useState, useEffect } from 'react';
import { fetchDataAuthenticated } from './HttpUtils';

export function useSiteCode() {
    const [siteCode, setSiteCode] = useState();

    useEffect(() => {
        const getSite = async function () {
            await fetchDataAuthenticated("api/config/siteCode")
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong.');
                })
                .then(data => {
                    setSiteCode(data);
                })
                .catch(error => {
                    console.log("Failed to get site code");
                });
        }

        getSite();
    }, []);

    return siteCode;
}