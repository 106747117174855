import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DraggableModalDialog } from './DraggableModalDialog';
import GradedCommentDialog from "./GradedCommentDialog";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from "react-i18next";

function GradedWarningDialog({ showDialog, closeDialog, onSave, action, warnings, gradedReasons }) {

    const { t } = useTranslation();
    const [showCommentDialog, setShowCommentDialog] = useState(false);

    const handleSaveWithComment = (comment, reasonCode) => {
        onSave(comment, reasonCode);
        closeDialog();
    }

    return (
        <>
            <Modal dialogAs={DraggableModalDialog} show={showDialog} onHide={closeDialog} backdrop="static" size="lg">
                <Modal.Header closeButton>
                    <FontAwesomeIcon icon={faTriangleExclamation} size="xl" className="me-3" />
                    <Modal.Title className="text-danger">Warnings on the lot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* TODO: Add translations for this*/}
                    <p><strong>There are outstanding warnings. Are you sure you wish to {action} the lot?</strong></p>
                    <ul>
                        {
                            warnings.map((w, i) => <li key={i}><p>{w.message}</p></li>)
                        }
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDialog}>
                        {t('Application.Controls.Cancel')} 
                    </Button>
                    <Button variant="primary" onClick={() => setShowCommentDialog(true)}>
                        {t('Application.Controls.Ok')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <GradedCommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} onSave={handleSaveWithComment} gradedReasons={gradedReasons} />
        </>
    );
}

export default GradedWarningDialog;