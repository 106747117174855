import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import { DraggableModalDialog } from '../../Dialogs/DraggableModalDialog';
import LotSplitBuilder from './LotSplitBuilder';
import LotSplitResult from './LotSplitResult';
import CommentDialog from '../../Dialogs/CommentDialog';
import { fetchDataAuthenticated } from '../../Utilities/HttpUtils';
import useAlert from '../../Alerts/useAlert';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

function LotSplitDialog({ showDialog, closeDialog, lotNumber, buildStart, buildEnd, getLotDetail, currentGrade, alternativeGrades, allGrades, loadingAlternativeGrades, loadingAllGrades, unreliableBaggingData }) {

    const initialRows = [{
        startTime: moment(new Date(buildStart)).format('YYYY-MM-DDTHH:mm'),
        endTime: moment(new Date(buildStart)).format('YYYY-MM-DDTHH:mm'),
        suffix: 'B'
    }];

    const [lotSplitRows, setLotSplitRows] = useState(initialRows);
    const [lotSplitResult, setLotSplitResult] = useState();
    const [formValidated, setFormValidated] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [disableConfirmSplit, setDisableConfirmSplit] = useState(true);
    const [showCommentDialog, setShowCommentDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingReason, setLoadingReason] = useState("");

    const { setAlert } = useAlert();

    useEffect(() => {
        if (loadingAlternativeGrades || loadingAllGrades) {
            setLoading(true);
            setLoadingReason("Loading grades");
        } else {
            setLoading(false);
        }
    }, [loadingAlternativeGrades, loadingAllGrades]);

    // Add new empty row to the lot split list
    const addRow = () => {

        // increment suffix
        let lastSuffix = lotSplitRows[lotSplitRows.length - 1].suffix;
        let suffix = String.fromCharCode(lastSuffix.charCodeAt() + 1)

        const emptyRow = {
            startTime: lotSplitRows[lotSplitRows.length - 1].endTime,
            endTime: lotSplitRows[lotSplitRows.length - 1].endTime,
            suffix: suffix,
            grade: ""
        };

        let tempRows = [...lotSplitRows];
        tempRows.push(emptyRow);

        setLotSplitRows(tempRows);
        setDisableConfirmSplit(true);
    }

    // Update the lotSplitRows state array with the form input
    const updateRow = (index, prop, value) => {
        let tempRows = [...lotSplitRows];
        let tempRow = { ...lotSplitRows[index] };

        tempRow[prop] = value;
        tempRows[index] = tempRow;

        setLotSplitRows(tempRows);
        setDisableConfirmSplit(true);
    }

    // Remove row at index from the lot split list
    const deleteRow = (index) => {
        let tempRows = [...lotSplitRows];
        tempRows.splice(index, 1);
        setLotSplitRows(tempRows);
        setDisableConfirmSplit(true);
    }

    // Validate the form and perform required action
    const handleSubmit = (e) => {
        let validSplit = validateSplit(e);

        if (validSplit) {
            let buttonClicked = e.nativeEvent.submitter.name;

            if (buttonClicked === "checkSplit") {
                checkLotSplit();
            }

            if (buttonClicked === "confirmSplit") {
                setShowCommentDialog(true);
            }
        } else {
            setLotSplitResult(undefined);
        }
    };

    // Validate the form
    const validateSplit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let validSplit = false;

        const form = e.currentTarget;

        if (form.checkValidity() === true) {

            // find any split dates that fall outside of the lot build date range
            let invalidDates = lotSplitRows.find((e) => {
                let startTimePlusOneMinute = moment(e.startTime).add(1, 'minutes').format('YYYY-MM-DDTHH:mm');
                return startTimePlusOneMinute < buildStart || e.endTime > buildEnd;
            });

            if (invalidDates) {
                setFormErrors({ ...formErrors, splitTime: "Split times cannot fall outside of the lot build date range" });
                console.warn("Split times cannot fall outside of the lot build date range");
            } else {
                setFormErrors({});
                validSplit = true;
            }
        } else {
            console.warn("Lot split form was invalid");
        }

        setFormValidated(true);
        return validSplit;
    }

    // Check the lot split and return bag numbers and weights
    const checkLotSplit = async () => {
        setLoading(true);
        setLoadingReason("Checking split");
        setDisableConfirmSplit(true);

        await fetchDataAuthenticated(`api/v1/lot/${lotNumber}/split/check`, "POST", JSON.stringify(lotSplitRows))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    let errorMessage = await response.text();
                    throw new Error(`Failed to split lot. ${errorMessage}`);
                }
            })
            .then(response => {
                setLotSplitResult(response);
                setDisableConfirmSplit(false);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    // Perform the lot split
    const splitLotWithComment = async (comment) => {

        setLoading(true);
        setLoadingReason("Splitting Lot");
        setDisableConfirmSplit(true);

        let body = {
            lotSplitSections: lotSplitRows,
            comment: comment
        };

        await fetchDataAuthenticated(`api/v1/lot/${lotNumber}/split`, "POST", JSON.stringify(body))
            .then(async response => {
                if (response.ok) {
                    setAlert("success", "Success", "Lot was split successfully");
                    getLotDetail();
                    closeDialogAndResetForm();
                } else {
                    let errorMessage = await response.text();
                    throw new Error(`Failed to split lot. ${errorMessage}`);
                }
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                setDisableConfirmSplit(false);
                setLoading(false);
            });
    }

    const closeDialogAndResetForm = () => {
        setLotSplitRows(initialRows);
        setLotSplitResult(null);
        setFormValidated(false);
        setDisableConfirmSplit(true);
        setLoading(false);
        setLoadingReason("");
        closeDialog();
    }

    return (
        <>
            <Modal dialogAs={DraggableModalDialog} show={showDialog} onHide={closeDialogAndResetForm} backdrop="static" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Split Lot {lotNumber}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={loading ? "fade-content" : ""}>
                    <Form id="lotSplitBuilder" noValidate validated={formValidated} onSubmit={handleSubmit}>
                        <LotSplitBuilder rows={lotSplitRows} updateRow={updateRow} deleteRow={deleteRow} currentGrade={currentGrade} alternativeGrades={alternativeGrades} allGrades={allGrades} />
                        <Button variant="primary" onClick={addRow}>Add Row</Button>
                        {formErrors.splitTime &&
                            <p className="text-danger mt-3">{formErrors.splitTime}</p>
                        }
                        {lotSplitResult && lotSplitResult.details.length > 0 &&
                            <LotSplitResult result={lotSplitResult} />
                        }
                        {lotSplitResult && lotSplitResult.details.length === 0 &&
                            <p className="text-danger mt-3">No results returned</p>
                        }
                        {unreliableBaggingData &&
                            <div className="d-flex justify-content-center">
                                <p className="text-danger"><FontAwesomeIcon icon={faTriangleExclamation} className="me-1 mt-3" /> Warning. Lot contains unreliable bagging data. Loadout counts cannot be trusted.</p>
                            </div>
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {loading &&
                        <div className="d-flex align-items-start me-auto">
                            <Spinner animation="border" size="md" className="me-3" />
                            <p className="mt-2">{loadingReason}</p>
                        </div>
                    }
                    <Button variant="secondary" onClick={closeDialogAndResetForm}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit" form="lotSplitBuilder" name="confirmSplit" disabled={disableConfirmSplit}>
                        Confirm
                    </Button>
                    <Button variant="primary" type="submit" form="lotSplitBuilder" name="checkSplit">
                        Check
                    </Button>
                </Modal.Footer>
            </Modal>
            <CommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} onSave={splitLotWithComment} />
        </>
    );
}

export default LotSplitDialog;