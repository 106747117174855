import React from "react";
import { Row, Col, Form, CloseButton } from 'react-bootstrap';

function LotSplitBuilder({ rows, updateRow, deleteRow, currentGrade, alternativeGrades, allGrades }) {

    const handleUpdateRow = (e) => {

        let index = e.target.dataset.id;
        let prop = e.target.name;
        let value = e.target.value;

        updateRow(index, prop, value);
    }

    return (
        rows.map((row, idx) => {

            let startTime = `startTime-${idx}`;
            let endTime = `endTime-${idx}`;
            let suffix = `suffix-${idx}`;
            let grade = `grade-${idx}`;
            let closeId = `closeId-${idx}`;

            return (
                <Row key={idx} className="mb-3">
                    <Col>
                        {idx === 0 &&
                            <p>Start time</p>
                        }
                        <Form.Group controlId={startTime}>
                            <Form.Control
                                type="datetime-local"
                                name="startTime"
                                data-id={idx}
                                required
                                value={row.startTime}
                                onChange={handleUpdateRow} >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        {idx === 0 &&
                            <p>End time</p>
                        }
                        <Form.Group controlId={endTime}>
                            <Form.Control
                                type="datetime-local"
                                name="endTime"
                                data-id={idx}
                                required
                                value={row.endTime}
                                onChange={handleUpdateRow} >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        {idx === 0 &&
                            <p>Suffix</p>
                        }
                        <Form.Group controlId={suffix}>
                            <Form.Control type="text" placeholder="Suffix"
                                name="suffix"
                                data-id={idx}
                                required
                                value={row.suffix}
                                maxLength={1}
                                onChange={handleUpdateRow}>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm={3}>
                        {idx === 0 &&
                            <p>Grade</p>
                        }
                        <Form.Group controlId={grade}>
                            <Form.Select
                                aria-label="Select grade"
                                name="grade"
                                data-id={idx}
                                required
                                value={row.grade}
                                onChange={handleUpdateRow}>
                                <option value="">Select...</option>
                                <option value={currentGrade}>{currentGrade}</option>
                                {alternativeGrades.map((grade, index) =>
                                    <option key={`grade-${index}`} value={grade.grade}>{grade.grade}</option>
                                )}
                                <option value="" disabled>------------</option>
                                {allGrades.map((grade, index) =>
                                    <option key={`grade-${alternativeGrades.length + 1 + index}`} value={grade}>{grade}</option>
                                )}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col sm={1}>
                        {idx > 0 &&
                            <Form.Group controlId={closeId}>
                                {idx === 0 &&
                                    <Form.Label style={{ visibility: "hidden" }}>Close</Form.Label>
                                }
                                <CloseButton onClick={() => deleteRow(idx)} />
                            </Form.Group>
                        }
                    </Col>
                </Row>
            )
        })
    );
}

export default LotSplitBuilder