import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Multiselect from 'multiselect-react-dropdown';

function MultiselectBox({ name, options, selectedOptions, setSelectedOptions, map }) {

    return (
        <Form.Group as={Row} controlId="multiselect">
            {name &&
                <Form.Label column xs={2} sm={3}>{name}:</Form.Label>
            }
            <Col xs={10} sm={9}>
                <Multiselect
                    onSelect={(e) => setSelectedOptions(e.map((obj => obj[map])))}
                    onRemove={(e) => setSelectedOptions(e.map((obj => obj[map])))}
                    options={options}
                    selectedValues={selectedOptions}
                    displayValue="name"
                    avoidHighlightFirstOption
                />
            </Col>
        </Form.Group>
    )
}

export default MultiselectBox;