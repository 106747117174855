import React, { useState, useEffect } from 'react';
import DataTable from '../../DataTable/datatable.component';
import BusinessUnitEditDialog from '../../Dialogs/Configuration/BusinessUnitEditDialog';
import Nav from 'react-bootstrap/Nav';
import CommonConfigPage from './CommonConfigPage';
import { fetchDataAuthenticated } from '../../Utilities/HttpUtils';

function BusinessUnitsTable({ onRowClick, includeDeleted, refresh }) {

    const [loaded, setLoaded] = useState(true);
    const [errored, setErrored] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const getBusinessUnits = async function () {
            await fetchDataAuthenticated(`api/v1/businessunit/?includeDeleted=${includeDeleted}`)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong.');
                })
                .then(data => {
                    setTableData(data);
                    setErrored(false);
                    setLoaded(true);
                })
                .catch(error => {
                    setErrored(true);
                    setLoaded(false);
                });
        };

        getBusinessUnits();
    }, [includeDeleted, refresh]);

    const tableColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'includesString',
            Cell: (props) => {
                return (<Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>)
            }
        },
        {
            Header: 'Code',
            accessor: 'code',
            filter: 'includesString'
        }
    ];

    const getRowProps = (row) => {
        if (row.original.isDeleted)
            return { className: "deleted" };

        return {};
    };

    var table = DataTable({ tableColumns: tableColumns, tableData: tableData, initialState: {} , getRowProps: getRowProps });

    let tableMarkup = loaded
        ? table
        : errored ? <p>There was a problem.</p>
            : < p > <em>Loading...</em></p>;

    return tableMarkup;
}

function BusinessUnitConfiguration() {
    const getNewBusinessUnit = () => {
        return { businessUnitId: null, name: "", code: "", isDeleted: false }
    };

    return (
        <CommonConfigPage
            title="Business Units"
            table={(includeDeleted, showEditDialog, refreshList) => <BusinessUnitsTable onRowClick={showEditDialog} includeDeleted={includeDeleted} refresh={refreshList} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <BusinessUnitEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} />}
            getNewConfigObject={getNewBusinessUnit}
        />
    );
}

export default BusinessUnitConfiguration;