import React from 'react';

function YesNoSelectFilter({ column: { filterValue, setFilter } }) {
    return (
        <select className="form-control form-control-sm"
            value={filterValue}
            onChange={e => { setFilter(e.target.value) }} >
            <option value="">All</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
        </select>
    )
}

export default YesNoSelectFilter;