import React, { useState } from 'react';
import CommonConfigDialog from './CommonConfigDialog';
import { RequiredDialogInput, DialogInput, RequiredDialogSelect, DialogSelect, DialogCheckbox, DialogInputNumeric } from '../../DialogInputs';
import { handleInputChange } from '../../Utilities/DialogUtils';
import { fetchDataAuthenticated } from '../../Utilities/HttpUtils';
import { useCalcFunctions, useLabFunctions, useOffspecFunctions } from '../../Utilities/Hooks';

function QualityEditDialog({ dialogData, setDialogData, showDialog, closeDialog, businessUnits }) {

    const [fieldErrors, setFieldErrors] = useState({});
    const calcFunctions = useCalcFunctions();
    const labFunctions = useLabFunctions();
    const offspecFunctions = useOffspecFunctions();

    const saveQuality = (quality) => {

        let promise = new Promise((resolve, reject) => {
            let url = `api/v1/quality`;
            if (quality.qualityId != null) {
                url += `/${quality.qualityId}`;
            }

            if (quality.defaultDecimalPlaces === "") {
                quality.defaultDecimalPlaces = null;
            }

            if (quality.specificGravity === "") {
                quality.specificGravity = null;
            }

            if (quality.displayOrder === "") {
                quality.displayOrder = null;
            }

            fetchDataAuthenticated(url, "POST", JSON.stringify(quality))
                .then(response => response.json())
                .then(data => {
                    if (data.errors !== null && data.errors !== undefined) {
                        reject(data);
                    }
                    resolve(data);
                })
                .catch(error => {
                    alert("there was a problem!" + error);
                });

        });

        return promise;
    };

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    const renderFunctionSelect = (controlId, title, name, functions) => {
        return (
            <DialogSelect controlId={controlId} title={title} name={name} value={dialogData[name]} error={fieldErrors[name]} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {functions.map((func, index) => <option value={func} key={index}>{func}</option>)}
            </DialogSelect>
        );
    }
    
    return (
        <CommonConfigDialog title={"Quality - " + dialogData.name} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveQuality} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} wideDialog={true}>
            <RequiredDialogInput controlId="formQualityName" title="Name" name="name" value={dialogData.name} error={fieldErrors.name} onChange={onChange} />
            <DialogInput controlId="formQualityDescription" title="Description" name="description" value={dialogData.description} error={fieldErrors.description} onChange={onChange} required={false}/>
            <DialogInput controlId="formQualityType" title="Type" name="type" value={dialogData.type} error={fieldErrors.type} onChange={onChange} required={false} />
            <DialogInput controlId="formQualityUnits" title="Units" name="units" value={dialogData.units} error={fieldErrors.units} onChange={onChange}/>
            <DialogCheckbox controlId="formQualityIsNumeric" title="Numeric?" name="isNumeric" checked={dialogData.isNumeric} error={fieldErrors.isNumeric} onChange={onChange}/>
            <DialogInputNumeric controlId="formQualityDefaultDPs" title="Default DPs" name="defaultDecimalPlaces" value={dialogData.defaultDecimalPlaces} error={fieldErrors.defaultDecimalPlaces} onChange={onChange}/>
            <RequiredDialogInput controlId="formQualityDefaultValue" title="Default Value To Use" name="defaultValueToUse" value={dialogData.defaultValueToUse} error={fieldErrors.defaultValueToUse} onChange={onChange} />
            <DialogInput controlId="formQualityAnalysis" title="Analysis" name="analysis" value={dialogData.analysis} error={fieldErrors.analysis} onChange={onChange} />
            <DialogInput controlId="formQualityComponent" title="Component" name="component" value={dialogData.component} error={fieldErrors.component} onChange={onChange} />
            <DialogInput controlId="formQualityReleaseAnalysis" title="Release Analysis" name="releaseAnalysis" value={dialogData.releaseAnalysis} error={fieldErrors.releaseAnalysis} onChange={onChange} />
            <DialogInput controlId="formQualityReleaseComponent" title="Release Component" name="releaseComponent" value={dialogData.releaseComponent} error={fieldErrors.releaseComponent} onChange={onChange} />
            <DialogInputNumeric controlId="formQualityDisplayOrder" title="Display Order" name="displayOrder" value={dialogData.displayOrder} error={fieldErrors.displayOrder} onChange={onChange} />
            <DialogInput controlId="formQualityMin" title="Min Value" name="masterMinValue" value={dialogData.masterMinValue} error={fieldErrors.masterMinValue} onChange={onChange} />
            <DialogInput controlId="formQualityMax" title="Max Value" name="masterMaxValue" value={dialogData.masterMaxValue} error={fieldErrors.masterMaxValue} onChange={onChange} />
            <DialogInput controlId="formQualitySpecificGravity" title="Specific Gravity" name="specificGravity" value={dialogData.specificGravity} error={fieldErrors.specificGravity} onChange={onChange} />
            {renderFunctionSelect("formQualityTransferCalc", "Transfer Calc Func", "transferCalculateFunction", calcFunctions)}
            { renderFunctionSelect("formQualityTransferOffspec", "Transfer Offspec Func", "transferIsOffspecFunction", offspecFunctions)}
            { renderFunctionSelect("formQualityBatchCalc", "Batch Calc Func", "batchCalculateFunction", calcFunctions)}
            { renderFunctionSelect("formQualityBatchLabCalc", "Batch Lab Calc Func", "batchLabCalculateFunction", labFunctions)}
            { renderFunctionSelect("formQualityBatchOffspec", "Batch Offspec Func", "batchIsOffspecFunction", offspecFunctions)}
            { renderFunctionSelect("formQualityLotCalc", "Lot Calc Func", "lotCalculateFunction", calcFunctions)}
            { renderFunctionSelect("formQualityLotLabCalc", "Lot Lab Calc Func", "lotLabCalculateFunction", labFunctions)}
            { renderFunctionSelect("formQualityLotOffspec", "Lot Offspec Func", "lotIsOffspecFunction", offspecFunctions)}
            { renderFunctionSelect("formQualityPackoutLotCalc", "PO Lot Calc Func", "packoutLotCalculateFunction", calcFunctions)}
            { renderFunctionSelect("formQualityPackoutLotLabCalc", "PO Lot Lab Calc Func", "packoutLotLabCalculateFunction", labFunctions)}
            { renderFunctionSelect("formQualityPackoutLotOffspec", "PO Lot Offspec Func", "packoutLotIsOffspecFunction", offspecFunctions)}
            <RequiredDialogSelect controlId="formQualityBusinessUnit" title="Business Unit" name="businessUnitId" value={dialogData.businessUnitId} error={fieldErrors.businessUnitId} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {businessUnits.map((bu) => <option value={bu.businessUnitId} key={bu.businessUnitId}>{bu.name}</option>)}
            </RequiredDialogSelect>
            <DialogCheckbox disabled={dialogData.qualityId === null} controlId="formQualityIsDeleted" title="Deleted?" name="isDeleted" checked={dialogData.isDeleted} error={fieldErrors.isDeleted} onChange={onChange}/>
        </CommonConfigDialog>
    );
}

export default QualityEditDialog;