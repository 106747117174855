import React, { useState, useEffect } from 'react';
import { useInterval } from 'usehooks-ts';
import DataTable from '../DataTable/datatable.component';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faAngleLeft, faAngleRight, faAnglesRight } from "@fortawesome/free-solid-svg-icons";


function ServerSideDataTable({ id, tableColumns, tableData, initialState, queryFunction, totalRecords, totalFilteredRecords, dependencyArray = [], refreshInterval = null }) {

    /** Props
      * tableColumns - array of table columns (same as client-side ReactTable)
      * tableData - array of objects (same as client-side ReactTable)
      * initialState - default table configuration (same as client-side ReactTable)
      * queryFunction - function to get the data. Will need to take params (pageNumber, pageSize, sorting, filters)
      * totalRecords - number of records in dataset if paging or filtering wasn't applied (used for paging)
      * totalFilteredRecords - number of records in dataset if paging wasn't applied (used for paging)
      * dependencyArray - array of properties that could cause the query to run again
      * refreshInterval - number of milliseconds for the query to refresh
     */

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(initialState.pageSize || 10);
    const [sorting, setSorting] = useState([]);
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        queryFunction(pageNumber, pageSize, sorting, filters);
    }, [pageNumber, pageSize, sorting, filters, ...dependencyArray])

    // update table at interval
    useInterval(() => {
        queryFunction(pageNumber, pageSize, sorting, filters);
    }, refreshInterval);

    return (
        <>
            {totalRecords > 0 &&
                <>
                    <DataTable id={id} tableColumns={tableColumns} tableData={tableData} initialState={initialState} isServerSideTable={true} setSorting={setSorting} setFilters={setFilters} />
                    <ManualPagination pageNumber={pageNumber} setPageNumber={setPageNumber} pageSize={pageSize} setPageSize={setPageSize} totalRecords={totalFilteredRecords < totalRecords ? totalFilteredRecords : totalRecords} />
                </>
            }
        </>
    );
}

function ManualPagination({ pageNumber, setPageNumber, pageSize, setPageSize, totalRecords }) {

    let pageCount = parseInt((totalRecords - 1) / pageSize) + 1
    let canNextPage = pageNumber < pageCount;
    let canPreviousPage = pageNumber > 1;

    if (pageNumber > pageCount) {
        setPageNumber(pageCount);
    }

    return (
        <div className="d-flex justify-content-end align-items-center">
            <div className="me-3">
                <Form.Select
                    id="pageSize"
                    size="sm"
                    value={pageSize}
                    onChange={e => {
                        setPageSize(e.target.value)
                        setPageNumber(1)
                    }}>
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </Form.Select>
            </div>
            <span className="align-items-center me-3">Page <strong>{pageNumber}</strong> of <strong>{pageCount}</strong></span>
            <div className="pagination">
                <button onClick={() => setPageNumber(1)} disabled={!canPreviousPage}>
                    <FontAwesomeIcon icon={faAnglesLeft} />
                </button>
                <button onClick={() => setPageNumber(pageNumber - 1)} disabled={!canPreviousPage}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <button onClick={() => setPageNumber(pageNumber + 1)} disabled={!canNextPage}>
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
                <button onClick={() => setPageNumber(pageCount)} disabled={!canNextPage}>
                    <FontAwesomeIcon icon={faAnglesRight} />
                </button>
            </div>
        </div>
    )
}

export default ServerSideDataTable;