import NoFilter from './datatable.nofilter';
import { DateToLocaleString, longDateAndTimeFormat } from '../Utilities/DateUtils';

function DateColumn({ header, accessor }) {
    return {
        Header: header,
        accessor: accessor,
        Cell: (props) => {
            return <div className="text-center">{DateToLocaleString(props.value, longDateAndTimeFormat)}</div>
        },
        Filter: NoFilter
    }
}

export default DateColumn;