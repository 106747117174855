import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTable, useFilters, useGlobalFilter, useExpanded, useSortBy } from 'react-table';
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BTable from 'react-bootstrap/Table';
import GlobalFilter from './datatable.globalfilter';
import { matchSorter } from 'match-sorter';
import DefaultColumnFilter from './datatable.columnfilter';

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

function ExpandingTable({ columns: userColumns, data, initialState, children }) {

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        [])

    const defaultColumn = React.useMemo(
        () => ({
            // Set default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter
        /*state: { expanded },*/
    } = useTable(
        {
            columns: userColumns,
            data,
            initialState: initialState,
            defaultColumn,
            filterTypes,
        }, // Use the useExpanded plugin hook
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded
    )

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        searchIcon={true} />
                </Col>
                <Col>
                    <div className="d-flex justify-content-end">
                        { /* show any filters that may have been passed in */ }
                        {children}
                    </div>
                </Col>
            </Row>
            <Row>
                <BTable responsive bordered hover size="sm" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        <div className="disable-select" {...column.getHeaderProps([column.getSortByToggleProps(), { className: column.className }])}>
                                            <span className="float-end">
                                                {column.isSorted ? column.isSortedDesc ? <FontAwesomeIcon icon={faSortDown} /> : <FontAwesomeIcon icon={faSortUp} /> : <div className="invisible"><FontAwesomeIcon icon={faSortUp} /></div>}
                                            </span>
                                            {column.render('Header')}
                                        </div>
                                        <div>
                                            {column.canFilter ? column.render('Filter') : null}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} className={row.depth > 0 ? "tr-subrow" : i % 2 === 0 ? "tr-odd" : "tr-even"}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </BTable>
            </Row>
        </>
    )
}

export default ExpandingTable;