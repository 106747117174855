import { Container, Tabs, Tab } from 'react-bootstrap';

import BusinessUnits from './Configuration/BusinessUnits';
import ConfigurationData from './Configuration/ConfigurationData';
import EquipmentTypes from './Configuration/EquipmentTypes';
import Equipment from './Configuration/Equipment';
import Lines from './Configuration/Lines';
import Qualities from './Configuration/Qualities';
import LineQualityData from './Configuration/LineQualityData';
import LabResultsConfig from './Configuration/LabResultsConfig';
import RecipeTypes from './Configuration/RecipeTypes';
import RecipeProperties from './Configuration/RecipeProperties';
import RecipeDataTypes from './Configuration/RecipeDataTypes';
import Recipes from './Configuration/Recipes';
import GradedReasons from './Configuration/GradedReasons';
import ChangeLog from './ChangeLog';

import { useTranslation } from 'react-i18next';
import { usePrivileges } from '../Auth/usePrivileges';

function Configuration() {

    const { t } = useTranslation();
    const { admin } = usePrivileges();

    return (
        <Container fluid>
            <h3 className="ms-2">Configuration</h3>
            <Tabs defaultActiveKey="businessUnits" id="settings-tabs" className="tabs mt-3 mb-3" mountOnEnter={true} justify>
                <Tab eventKey="businessUnits" title={t('Configuration.Tabs.BusinessUnits')} >
                    <BusinessUnits />
                </Tab>
                <Tab eventKey="configData" title={t('Configuration.Tabs.ConfigurationData')} >
                    <ConfigurationData />
                </Tab>
                <Tab eventKey="equipmentTypes" title={t('Configuration.Tabs.EquipmentTypes')} >
                    <EquipmentTypes />
                </Tab>
                <Tab eventKey="equipment" title={t('Configuration.Tabs.Equipment')} >
                    <Equipment />
                </Tab>
                <Tab eventKey="lines" title={t('Configuration.Tabs.Lines')} >
                    <Lines />
                </Tab>
                <Tab eventKey="qualities" title={t('Configuration.Tabs.Qualities')} >
                    <Qualities />
                </Tab>
                <Tab eventKey="lineQualityData" title={t('Configuration.Tabs.LineQualityData')} >
                    <LineQualityData />
                </Tab>
                <Tab eventKey="labResultsConfig" title={t('Configuration.Tabs.LabResultsConfig')} >
                    <LabResultsConfig />
                </Tab>
                <Tab eventKey="recipeTypes" title={t('Configuration.Tabs.RecipeTypes')} >
                    <RecipeTypes />
                </Tab>
                <Tab eventKey="recipeProperties" title={t('Configuration.Tabs.RecipeProperties')} >
                    <RecipeProperties />
                </Tab>
                <Tab eventKey="recipeDataTypes" title={t('Configuration.Tabs.RecipeDataTypes')} >
                    <RecipeDataTypes />
                </Tab>
                <Tab eventKey="recipes" title={t('Configuration.Tabs.Recipes')} >
                    <Recipes />
                </Tab>
                <Tab eventKey="gradedReasons" title={t('Configuration.Tabs.GradedReasons')} >
                    <GradedReasons />
                </Tab>
                { admin &&
                    <Tab eventKey="changeLog" title={t('Configuration.Tabs.ChangeLog')} >
                        <ChangeLog />
                    </Tab>
                }
            </Tabs>
        </Container >
    );
}

export default Configuration;