import React, { Suspense, useMemo } from 'react';

class SiteComponentLoaderErrorBoundary extends React.Component {
    state = { hasError: false, error: null };

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    render() {
        var Fallback = this.props.fallback;
        return this.state.hasError ? <Fallback /> : this.props.children;
    }
}

function SiteComponentLoader({ component, site, tableFilters }) {

    const SiteComponent = useMemo(() => React.lazy(() => import(component + "_" + site + ".js")), [component, site]);
    const Component = useMemo(() => React.lazy(() => import(component + ".js")), [component]);

    return (
        <Suspense fallback={<div>Loading</div>}>
            <SiteComponentLoaderErrorBoundary fallback={Component}>
                <SiteComponent tableFilters={tableFilters} />
            </SiteComponentLoaderErrorBoundary>
        </Suspense>
    );
}

export default SiteComponentLoader;