import React from 'react';
import {Container, Tabs, Tab} from 'react-bootstrap';
import Lots from "./Reporting/Lots";
import Qirs from "./Reporting/QualityIncidentReports";

function Reporting({ status }) {
    

    return (
        <Container fluid>
            <Tabs defaultActiveKey="lots" id="reporting-tabs" className="tabs mt-3 mb-3" mountOnEnter={true} fill>
                <Tab eventKey="lots" title="Lots">
                    <Lots status={status}/>
                </Tab>
                <Tab eventKey="qirs" title="Qirs">
                    <Qirs status={status}/>
                </Tab>
            </Tabs>
        </Container >
    );
}

export default Reporting;