import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CorporateDlg from './Dialogs/FooterDialogs/CorporateDlg';
import SupportDlg from './Dialogs/FooterDialogs/SupportDlg';
import AboutDlg from './Dialogs/FooterDialogs/AboutDlg';
import { Container, Row, Col } from 'react-bootstrap';
import UserContext from './Contexts/UserContext';
import ConfigurationContext from './Contexts/ConfigurationContext';
import { fetchDataAuthenticated } from './Utilities/HttpUtils';

function Footer() {

    const { i18n } = useTranslation();
    const userContext = useContext(UserContext);
    const configurationContext = useContext(ConfigurationContext);

    const [version, setVersion] = useState("");
    const [copywriteInfo, setCopywriteInfo] = useState("");
    const [timezone, setTimezone] = useState();
    const [languages, setLanguages] = useState(["En"]);
    const [corporateDlgIsOpen, setCorporateDlgIsOpen] = useState(false);
    const [supportDlgIsOpen, setSupportDlgIsOpen] = useState(false);
    const [aboutDlgIsOpen, setAboutDlgIsOpen] = useState(false);

    /* Show the timezone in the footer or "Not set" if no configuration found */
    useEffect(() => {
        if (configurationContext.configurationData.length > 0) {
            let timezoneConfig = configurationContext.configurationData.find(element => element.name === "TimeZoneName");

            if (timezoneConfig) {
                setTimezone(timezoneConfig.value);
            } else {
                console.warn("Timezone not found");
                setTimezone("Not set");
            }
        }
    }, [configurationContext]);

    /* List the available languages as links in the footer, or default to En  */
    useEffect(() => {

        if (configurationContext.configurationData.length > 0) {

            let supportedLanguages = [];

            fetchDataAuthenticated('api/config/languages')
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error(response);
                })
                .then(data => {
                    supportedLanguages = data;
                })
                .catch(error => {
                    console.error("Failed to retrieve languages");
                    console.error(error);
                });

            let languagesConfig = configurationContext.configurationData.find(element => element.name === "Languages");

            if (languagesConfig) {

                for (const lang of languagesConfig.value.split(',')) {

                    if (supportedLanguages.includes(lang) && !languages.includes(lang)) {
                        setLanguages(languages => [...languages, lang]);
                    } else {
                        if (lang !== "En") {
                            console.warn(`Language ${lang} already added or not supported`);
                        }
                    }
                }

            } else {
                console.warn("Languages not found. Default to En");
            }
        }
    }, [configurationContext]);

    useEffect(() => {
        fetchDataAuthenticated('api/info/app')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error();
            })
            .then(data => {
                setVersion(data.version);
                setCopywriteInfo(data.copywriteInfo);
            })
            .catch(error => {
                console.log("Failed to retrieve app info");
            });
    }, []);

    const toggleCorporateDlg = () => {
        setCorporateDlgIsOpen(!corporateDlgIsOpen);
    };

    const toggleSupportDlg = () => {
        setSupportDlgIsOpen(!supportDlgIsOpen);
    };

    const toggleAboutDlg = () => {
        setAboutDlgIsOpen(!aboutDlgIsOpen);
    };

    return (
        <>
            <Container fluid className="footer border-top">
                <Row>
                    <Col className="d-flex justify-content-start">
                        <Link className="link me-2" to='#' onClick={toggleAboutDlg}>About</Link>
                        <Link className="link me-3" to='#' onClick={toggleSupportDlg}>Support</Link>
                        {languages.map((lng, index) => (
                            <Link className="link me-2" to='#' key={index}
                                style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }}
                                onClick={() => i18n.changeLanguage(lng)}>
                                {lng}
                            </Link>
                        ))}
                    </Col>
                    <Col>
                        {timezone &&
                            <p><strong>Current timezone: </strong> {timezone}</p>
                        }
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-end">
                            <Link className="link me-2" to='#' onClick={toggleCorporateDlg}>Corporate Separateness Notice</Link>
                            <p className="text-dark d-none d-md-inline-block">{copywriteInfo}</p>
                        </div>
                    </Col>
                </Row>
            </Container>

            <CorporateDlg isOpen={corporateDlgIsOpen} toggleModal={toggleCorporateDlg} />
            <SupportDlg isOpen={supportDlgIsOpen} toggleModal={toggleSupportDlg} />
            <AboutDlg isOpen={aboutDlgIsOpen} toggleModal={toggleAboutDlg} userName={userContext.userName}
                privileges={userContext.privileges} version={version} copywrite={copywriteInfo} />
        </>
    )
}

export default Footer;