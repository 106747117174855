import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Container, Col, Row } from 'react-bootstrap';
import { DraggableModalDialog } from './DraggableModalDialog';
import CommentDialog from "../Dialogs/CommentDialog";

function QualityValueDialog({ showDialog, closeDialog, title, quality, onSave, allowManualEdit }) {

    const { t } = useTranslation();

    const [qualityValues, setQualityValues] = useState(quality.qualityValues);
    const [showCommentDialog, setShowCommentDialog] = useState(false);
   
    const MANUAL_TEST_METHOD = "ALTERNATE";

    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowCommentDialog(true);
    }

    const handleSaveWithComment = (comment) => {
        const updatedQuality = { ...quality, qualityValues: qualityValues }

        onSave(updatedQuality, comment);
        closeDialog();
    }

    const handleQualityChange = (e) => {
        let type = e.target.value;

        setQualityValues(current =>
            current.map(qv => {
                if (qv.testMethod === type) {
                    var newStatus = qv.status.replace(" Unused", "");
                    return { ...qv, status: newStatus, graderSelected: true };
                }

                if (qv.testMethod !== type && !qv.status.includes("Unused")) {
                    return { ...qv, status: qv.status + " Unused", graderSelected: false };
                }

                return qv;
            })
        );
    }

    const handleManualQualityValueChange = (e) => {

        let value = e.target.value;

        let existingQualityValue = qualityValues.find(qv => qv.resultType);
        let resultType = existingQualityValue ? existingQualityValue.resultType : "";

        // Add new manual quality value if one doesn't exist
        var qualityValue = quality.qualityValues.find(qv => qv.testMethod === MANUAL_TEST_METHOD);
        if (!qualityValue) {
            console.log("No manual quality value found. Adding the manual entry");

            let manualQualityValue = {
                resultValue: value,
                resultType: resultType,
                status: "Hi Unused",
                testMethod: MANUAL_TEST_METHOD,
                qualityValueSamples: [],
                isDefault: false,
                graderSelected: true
            }

            quality.qualityValues.push(manualQualityValue);
        }

        // Update manual quality value value and set as selected quality value
        setQualityValues(current =>
            current.map(qv => {
                if (qv.testMethod === MANUAL_TEST_METHOD) {
                    var newStatus = qv.status.replace(" Unused", "");

                    return { ...qv, status: newStatus, resultValue: value, graderSelected: true };
                }

                if (qv.testMethod !== MANUAL_TEST_METHOD && !qv.status.includes("Unused")) {

                    return { ...qv, status: qv.status + " Unused", graderSelected: false };
                }

                return qv;
            }),
        );
    }

    const ValueRow = ({ label, id, type }) => {

        let value = "";
        let selected = false;

        let qualityValue = qualityValues.find(x => x.testMethod === type);
        if (qualityValue) {
            value = qualityValue.resultValue;

            if (qualityValue.testMethod === "ASADDED") {
                value += ` (${qualityValue.status.substring(0, 2)})`;
            }

            selected = !qualityValue.status.includes("Unused");
        }

        return (
            <Row>
                <Col xs={2}></Col>
                <Form.Label column xs={3}>{label} :</Form.Label>
                <Col xs={1}>
                    <Form.Check type="radio" id={id} name="qualityValue" value={type} checked={selected} onChange={handleQualityChange} disabled={value === ""} />
                </Col>
                <Col xs={3}>
                    <Form.Control type="text" readOnly placeholder={value} />
                </Col>
            </Row>
        );
    }

    let manualValue = "";
    let manualSelected = false;

    let qualityValue = qualityValues.find(x => x.testMethod === MANUAL_TEST_METHOD);
    if (qualityValue) {
        manualValue = qualityValue.resultValue;
        manualSelected = !qualityValue.status.includes("Unused");
    }

    return (
        <>
            <Modal dialogAs={DraggableModalDialog} show={showDialog} onHide={closeDialog} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSave} id="qualityValuesForm">
                        <Container>
                            <Row><Form.Label className="fw-bold" >Selected value in use:</Form.Label></Row>
                            <ValueRow label={t("QualityValue.Primary")} id="rdoPrimary" type="ASADDED" />
                            <ValueRow label={t("QualityValue.Secondary")} id="rdoSecondary" type="SECONDARYASADDED" />
                            <ValueRow label={t("QualityValue.Tertiary")} id="rdoTertiary" type="TERTIARYASADDED" />
                            <Row>
                                <Col xs={2}></Col>
                                <Form.Label column xs={3}>{ t("QualityValue.Manual") } :</Form.Label>
                                <Col xs={1}>
                                    <Form.Check type="radio" id="rdoManual" name="qualityValue" value={MANUAL_TEST_METHOD} checked={manualSelected} onChange={handleQualityChange} disabled={!allowManualEdit || manualValue === ""} />
                                </Col>
                                <Col xs={3}>
                                    <Form.Control type="text" readOnly={!allowManualEdit} value={manualValue} onChange={handleManualQualityValueChange} />
                                </Col>
                            </Row>
                            <ValueRow label={t("QualityValue.L3Lab")} id="rdoL3Lab" type="LABCALC" />
                            <ValueRow label={t("QualityValue.LBMLab")} id="rdoLBMLab" type="LBMLABCALC" />
                        </Container>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDialog}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit" form="qualityValuesForm">
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <CommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} onSave={handleSaveWithComment} />
        </>
    );
}

export default QualityValueDialog;