import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from '../../../DataTable/datatable.component';
import NoFilter from '../../../DataTable/datatable.nofilter';
import LabDetailsDialog from '../../../Dialogs/LabDetailsDialog';
import QualityValueDialog from '../../../Dialogs/QualityValueDialog';
import QualityGraphDialog from '../../../Dialogs/QualityGraphDialog';
import { fetchDataAuthenticated } from '../../../Utilities/HttpUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faChartLine } from '@fortawesome/free-solid-svg-icons';
import useAlert from '../../../Alerts/useAlert';
import usePrivileges from '../../../Auth/usePrivileges';

function QualitiesSection({ qualities, businessUnitCode, lotNumber, lotDetail, setLotDetail }) {

    const { t } = useTranslation();
    const { setAlert } = useAlert();
    const { canReview } = usePrivileges(businessUnitCode);

    // This function is used for immediate update of the qualities table when
    // a quality value or selected value is changed.
    const updateQuality = (quality) => {
        var qualityIndex = qualities.findIndex(q => q.name === quality.name);
        if (quality) {
            var newQualities = qualities;
            newQualities[qualityIndex] = quality;

            setLotDetail({
                ...lotDetail, qualities: newQualities
            });
        }
    }

    const handleSave = (quality, comment) => {

        quality.comment = comment

        fetchDataAuthenticated(`/api/v1/lot/${lotNumber}/qualityValues`, 'PUT', JSON.stringify(quality))
            .then(response => {
                if (response.ok) {
                    setAlert("success", "Success", "Quality values updated");
                    updateQuality(quality);
                } else {
                    throw new Error('Failed to update quality values');
                }
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            });
    }

    var tableColumns = [
        {
            Header: t('LotDetails.Qualities.Name'),
            accessor: 'name',
            filter: 'includesString'
        },
        QualityColumn({ header: t('LotDetails.Qualities.Primary'), qualityType: 'ASADDED' }),
        {
            Header: t('LotDetails.Qualities.QualityValueConfidence'),
            accessor: 'confidence',
            Cell: (props) => {
                var result = props.row.original.qualityValues.find(qv => qv.testMethod === "ASADDED")
                return result ? result.status.substring(0, 2) : "";
            },
            Filter: NoFilter
        },
        QualityColumn({ header: t('LotDetails.Qualities.Secondary'), qualityType: 'SECONDARYASADDED' }),
        QualityColumn({ header: t('LotDetails.Qualities.Tertiary'), qualityType: 'TERTIARYASADDED' }),
        QualityColumn({ header: t('LotDetails.Qualities.Manual'), qualityType: 'ALTERNATE' }),
        QualityColumn({ header: t('LotDetails.Qualities.L3Lab'), qualityType: 'LABCALC' }),
        LBMLabColumn({ header: t('LotDetails.Qualities.LBMLab'), qualityType: 'LBMLABCALC' }),
        {
            Header: t('LotDetails.Qualities.Min'),
            accessor: 'min',
            Filter: NoFilter
        },
        {
            Header: t('LotDetails.Qualities.Target'),
            accessor: 'target',
            Filter: NoFilter
        },
        {
            Header: t('LotDetails.Qualities.Max'),
            accessor: 'max',
            Filter: NoFilter
        },
        {
            Header: t('LotDetails.Qualities.Units'),
            accessor: 'units',
            Filter: NoFilter
        },

        PPKColumn(),
        {
            accessor: 'editButton',
            Filter: NoFilter,
            Cell: (props) => {
                return (<QualityButtonCell row={props.row} lotNumber={lotNumber} handleSave={handleSave} allowManualEdit={canReview} />)
            }
        },
        {
            accessor: 'showGraphButton',
            Filter: NoFilter,
            Cell: (props) => {
                return (<QualityGraphGeneratorCell row={props.row} lotNumber={lotNumber} handleSave={handleSave} />)
            }
        },
        {
            accessor: 'displayOrder'
        }
    ];

    const getRowProps = (row) => {
        if (row.original.isOffspec)
            return { className: "offspec" };

        return {};
    };

    const initialState = {
        hiddenColumns: ['displayOrder'],
        sortBy: [
            {
                id: 'displayOrder',
                desc: false
            }
        ],
        pageSize: 100
    };

    var table = DataTable({
        tableColumns: tableColumns, tableData: qualities, initialState: initialState, getRowProps: getRowProps
    });

    return qualities.length > 0 ? table : "No data";
}

function QualityButtonCell({ row, lotNumber, handleSave, allowManualEdit }) {

    const [showQualityValues, setShowQualityValues] = useState(false);

    return (
        <>
            <FontAwesomeIcon className="link" icon={faPenToSquare} onClick={() => setShowQualityValues(true)} />
            <QualityValueDialog showDialog={showQualityValues} closeDialog={() => setShowQualityValues(false)} title={lotNumber + " - " + row.original.component} quality={row.original} onSave={handleSave} allowManualEdit={allowManualEdit} />
        </>
    );
}

function QualityGraphGeneratorCell({ row, lotNumber }) {

    const [showQualityGraph, setShowQualityGraph] = useState(false);

    return (
        <>
            <FontAwesomeIcon className="link" icon={faChartLine} onClick={() => setShowQualityGraph(true)} />
            {showQualityGraph &&
                <QualityGraphDialog showDialog={showQualityGraph} closeDialog={() => setShowQualityGraph(false)} title={lotNumber + " - " + row.original.component} lotNumber={lotNumber} quality={row.original} />
            }
        </>
    );
}

function QualityCell({ row, qualityType }) {

    let value = null;
    let className = "";

    row.original.qualityValues.forEach(function (qualityValue) {

        if (qualityValue.testMethod === qualityType) {
            value = qualityValue.resultValue;
            className = GetQualityColumnClass(qualityValue);
        }
    });

    return (<div className={'quality-value-column ' + className} >{value}</div>);
}

function QualityColumn({ header, qualityType }) {
    return {
        Header: header,
        accessor: header,
        Cell: (props) => {
            return (<QualityCell row={props.row} qualityType={qualityType} />);
        },
        Filter: NoFilter
    }
}

function PPKColumn() {

    const { t } = useTranslation();

    return {
        Header: t('LotDetails.Qualities.PPK'),
        accessor: 'ppk',
        Cell: (props) => {
            let ppk = props.row.original.qualityValues.find(qv => qv.testMethod === "PPK")
            return ppk && ppk.resultValue !== "" ? Number(ppk.resultValue).toFixed(2) : "";
        },
        Filter: NoFilter
    }
}

function GetQualityColumnClass(qualityValue) {
    let className = "";

    if (!qualityValue.status.includes("Unused")) {
        className = "selected";
    }
    else if (qualityValue.isDefault) {
        className = "default-value";
    }

    if (qualityValue.graderSelected) {
        className += " fst-italic";
    }

    return className;
}

function LBMLabColumn({ header, qualityType }) {
    return {
        Header: header,
        accessor: header,
        Cell: (props) => {
            return (<LBMLabCell row={props.row} qualityName={props.row.original.name} qualityType={qualityType} />);
        },
        Filter: NoFilter
    }
}

function LBMLabCell({ row, qualityName, qualityType }) {

    const [showLabDetails, setShowLabDetails] = useState(false);

    let samples = [];
    let value = null;
    let className = "";

    row.original.qualityValues.forEach(function (qualityValue) {
        if (qualityValue.testMethod === qualityType) {
            samples = qualityValue.qualityValueSamples;
            value = qualityValue.resultValue + "(" + samples.length + ")";
            className = GetQualityColumnClass(qualityValue) + " lab";
        }
    });

    return (
        <>
            <a className={'quality-value-column link ' + className} onClick={() => setShowLabDetails(true)}>{value}</a>
            <LabDetailsDialog showDialog={showLabDetails} closeDialog={() => setShowLabDetails(false)} qualityName={qualityName} samples={samples} />
        </>
    );
}

export default QualitiesSection;