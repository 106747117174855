export function addSorting(params, sorting) {
    if (sorting && sorting.length > 0) {
        params.append("$orderby", sorting.map(e => `${e.id} ${e.desc ? "desc" : "asc"}`).toString());
    }
}

export function addFilters(params, filters) {
    if (filters && filters.length > 0) {

        let filterString = "";
        filters.forEach((e, i) => {
            let clause = "";
            if (e.filter === "equals") {
                clause = `${e.id} eq '${e.value}'`;
            } else {
                clause = `contains(cast(${e.id}, 'Edm.String'),'${e.value}')`;
            }

            if (i < filters.length - 1) {
                clause += " and ";
            }

            filterString += clause;
        });

        params.append("$filter", filterString);
    }
}

export function addPaging(params, pageSize, pageNumber) {
    params.append("$skip", pageSize * (pageNumber - 1));
    params.append("$top", pageSize);
}