export function sortByPropertyName(propertyName, order = 'asc') {
    return (a, b) => {
        if (!a.hasOwnProperty(propertyName) || !b.hasOwnProperty(propertyName)) {
            return 0;
        }

        let result = 0;
        if (a[propertyName].toLowerCase() > b[propertyName].toLowerCase()) {
            result = 1;
        } else if (a[propertyName].toLowerCase() < b[propertyName].toLowerCase()) {
            result = -1;
        }
        return order === 'desc' ? result * -1 : result
    };
}