import React, { useMemo } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { faSortDown, faSortUp, faAnglesLeft, faAngleLeft, faAngleRight, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultColumnFilter from '../../../DataTable/datatable.columnfilter';
import GlobalFilter from '../../../DataTable/datatable.globalfilter';
import { matchSorter } from 'match-sorter';
import BTable from 'react-bootstrap/Table';

function CommentsTable({ columns, data, initialState, addGradingCommentButton }) {

    const filterTypes = useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
            jsonArray: (rows, id, filterValue) => {

                if (filterValue) {
                    filterValue = JSON.parse(filterValue);

                    return rows.filter(row => {
                        let rowValue = row.values[id];

                        // filter value will return the rows, or nothing if there are no items
                        return filterValue.value !== ""
                            ? filterValue.value.split(",").includes(rowValue ?? "")
                            : false;
                    });
                } else {
                    // no filter value will return all rows
                    return rows;
                }
            }
        }), [])

    function fuzzyTextFilterFn(rows, id, filterValue) {
        return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
    }

    const defaultColumn = useMemo(
        () => ({
            // Set default Filter UI
            Filter: DefaultColumnFilter,
        }), []);

    // Let the table remove the filter if the string is empty
    fuzzyTextFilterFn.autoRemove = val => !val

    const {
        //rows,
        page,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: initialState,
            defaultColumn,
            filterTypes,
            // Setting the following properties to false
            // the hooks cause maximum exceeded depth
            autoResetFilters: false,
            autoResetGlobalFilter: false,
            autoResetHiddenColumns: false,
            autoResetSortBy: false,
            autoResetPage: false
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    function showPagination() {
        if (pageOptions.length > 1) {
            return (
                <div className="d-flex justify-content-end align-items-center">
                    <div className="me-3">
                        <Form.Select
                            id="pageSize"
                            size="sm"
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                    <div className="pagination me-4">
                        <span className="align-items-center me-3">Page <strong>{pageIndex + 1}</strong> of <strong>{pageOptions.length}</strong></span>
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            <FontAwesomeIcon icon={faAnglesLeft} />
                        </button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            <FontAwesomeIcon icon={faAnglesRight} />
                        </button>
                    </div>
                </div>
            )
        }
    }

    function showTable() {
        return (
            <>
                <Row>
                    <Col sm={6} className="mb-2">
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            searchIcon={true} />
                    </Col>
                    <Col sm={6} className="mb-2">
                        {
                            // Render filter for reason
                            headerGroups[0].headers.find(h => h.Header === "Reason") &&
                            headerGroups[0].headers.find(h => h.Header === "Reason").render('Filter')
                        }
                    </Col>
                </Row>
                <Row>
                    <BTable size="sm" {...getTableProps()} className="comments-table">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()}>
                                            <div className="disable-select" {...column.getHeaderProps([column.getSortByToggleProps(), { className: column.className }])}>
                                                <span className="float-end">
                                                    {column.isSorted ? column.isSortedDesc ? <FontAwesomeIcon icon={faSortDown} /> : <FontAwesomeIcon icon={faSortUp} /> : <div className="invisible"><FontAwesomeIcon icon={faSortUp} /></div>}
                                                </span>
                                                {column.render('Header')}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </BTable>
                </Row>
            </>
        );
    }

    return (
        <>
            <div>
                {showTable()}
            </div>
            {addGradingCommentButton &&
                <div className="d-flex justify-content-between">
                    {addGradingCommentButton}
                    {showPagination()}
                </div>
            }
            {!addGradingCommentButton &&
                <div className="d-flex justify-content-end">
                    {showPagination()}
                </div>
            }
        </>
    );
}

export default CommentsTable;