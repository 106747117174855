import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import { useSiteCode } from '../../Utilities/useSiteCode';
import { useInterval } from 'usehooks-ts';
import useLotDetails from './useLotDetails';

import LotDetails from './LotDetails';
import LotDetails_BAPP from './LotDetails_BAPP';

function LotDetailsSelector() {

    const siteCode = useSiteCode();
    const { lotNumber } = useParams();
    const { lotDetail, getLotDetail, setLotDetail, loading, setLoading, checkForLotChanges } = useLotDetails(lotNumber);


    // refresh lot details
    useInterval(async () => {
        if (lotDetail) {
            let lotHasChanged = await checkForLotChanges(
                lotNumber,
                lotDetail.revisions[lotDetail.revisions.length - 1].lotRevisionId,
                lotDetail.summary.currentStatus,
                lotDetail.summary.processingStatus,
                lotDetail.gradingComments.length);

            if (lotHasChanged) {
                console.log("The lot has changed. Updating lot details..");
                getLotDetail();
            }
        }
    }, 20000);

    if (loading) {
        return (
            <div className="overlay-content">
                <Spinner animation="border" />
            </div>
        );
    }

    switch (siteCode) {
        case "BAPP":
            return <LotDetails_BAPP lotDetail={lotDetail} getLotDetail={getLotDetail} setLotDetail={setLotDetail} loading={loading} setLoading={setLoading} />

        default:
            return <LotDetails lotDetail={lotDetail} getLotDetail={getLotDetail} setLotDetail={setLotDetail} loading={loading} setLoading={setLoading} />
    }
}

export default LotDetailsSelector;