import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { usePrivileges } from '../../../Auth/usePrivileges';
import { fetchDataAuthenticated } from '../../../Utilities/HttpUtils';
import useAlert from '../../../Alerts/useAlert';

function WarningsSection({ warnings, lotNumber, getLotDetail, businessUnitCode }) {

    const { t } = useTranslation();
    const { setAlert } = useAlert();
    const { canProcess, admin } = usePrivileges(businessUnitCode);

    if (warnings) {
        var warningList = warnings.map((warning, index) => (
            <li key={index}>
                <p>{warning.message} {warning.category === "BLOCKED" && 
                    <Button variant="primary me-1"
                        disabled={!canProcess && !admin}
                        onClick={() => unblockLot()}>
                        {t('LotDetails.Warnings.Unblock')}
                    </Button>}</p>
            </li>
        ));
    }

    const unblockLot = () => {
        fetchDataAuthenticated(`/api/v1/lot/${lotNumber}/unblock`, 'PUT')
            .then(async response => {
                if (response.ok) {
                    getLotDetail();
                } else {
                    throw new Error("Failed to unblock the lot");
                }
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            });
    }

    return warnings?.length > 0 ? <ul className="warnings">{warningList}</ul> : "None";
}

export default WarningsSection;