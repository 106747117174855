import React, { useState, useEffect } from 'react';
import { Spinner, Alert } from 'react-bootstrap';
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from 'react-fusioncharts';
import { fetchDataAuthenticated } from '../Utilities/HttpUtils';

ReactFC.fcRoot(FusionCharts, TimeSeries);

// Some odd issues can be seen in graph depending on your system Timezone. Setting it to one which doesn't do daylight savings seems to work (ie UTC not GMT) - Fusion Support raised a ticket for this and 'in future version it would be mitigated'.
const dataSource = {
    chart: {
        "palettecolors": "FFC533,29c3be,f2726f,2762B5,FF0000"
    },
    dataMarker: [],
    navigator: {
        enabled: 0
    },
    caption: {
        text: ""
    },
    subcaption: {
        text: ""
    },
    series: "Type",
    yAxis: {
        plot: [
            {
                value: "Value",
                connectnulldata: true
            },
            {
                value: "Lab Value"
            }
        ],
        title: "Value",
        referenceLine: []
    },
    xAxis: {
        binning: {
            "year": [],
            "month": [],
            "day": [],
            "hour": [],
            "minute": [1]
        },
        showTimeSpanMarkerTooltipAt: 0,
        timemarker: []
    }
};

function QualityGraph({ lotNumber, component }) {

    const [timeseriesDs, setTimeseriesDs] = useState({
        type: "timeseries",
        renderAt: "container",
        width: "100%",
        height: "800",
        dataSource
    });

    const [error, setError] = useState();
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(true);

    const schema = [
        {
            "name": "Time",
            "type": "date",
            "format": "%y-%m-%d %H:%M:%S"
        },
        {
            "name": "Type",
            "type": "string"
        },
        {
            "name": "Value",
            "type": "number"
        },
        {
            "name": "Lab Value",
            "type": "number"
        }
    ];

    useEffect(() => {

        setLoading(true);

        const fetchData = async () => {
            await fetchDataAuthenticated(`api/v1/Graph?lotNumber=${lotNumber}&qualityName=${component}`)
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error(await response.json());
                    }
                })
                .then(data => {
                    const myArray = [];
                    const timeMarkerArray = [];
                    const dataMarkerArray = [];

                    if (data) {
                        if (data.dataSet) {
                            data.dataSet.forEach(element => {
                                myArray.push([element.time, element.type, element.data, element.labValue])
                                if (element.labValue) {
                                    dataMarkerArray.push({
                                        value: "Lab Value",
                                        time: element.time,
                                        identifier: element.identifier,
                                        timeFormat: "%y-%m-%d %H:%M:%S"
                                    })
                                }
                            });
                        }

                        if (data.batchInfo) {
                            data.batchInfo.forEach(batch => {
                                timeMarkerArray.push({
                                    start: batch.start,
                                    end: batch.end,
                                    label: batch.name,
                                    timeformat: "%y-%m-%d %H:%M:%S",
                                    type: "full",
                                    style: {
                                        marker: {
                                            fill: batch.color
                                        }
                                    }
                                })
                            });
                        }
                    }

                    const fusionTable = new FusionCharts.DataStore().createDataTable(myArray, schema);

                    const timeseries = Object.assign({}, timeseriesDs);
                    timeseries.dataSource.data = fusionTable;
                    timeseries.dataSource.xAxis.timemarker = timeMarkerArray;
                    timeseries.dataSource.dataMarker = dataMarkerArray;

                    setTimeseriesDs(timeseries);

                    setError(false);
                    setErrorMessage("");
                })
                .catch(error => {
                    setError(true);
                    setErrorMessage(error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        fetchData();
    }, [lotNumber, component]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center mb-3">
                <Spinner animation="border" />
            </div>
        );
    }

    if (error) {
        return <Alert variant="danger">{errorMessage}</Alert>
    }

    return <ReactFC {...timeseriesDs} />
}

export default QualityGraph;