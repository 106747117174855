import React, { useState, useEffect } from 'react';
import DataTable from '../../DataTable/datatable.component';
import SelectFilter from '../../DataTable/datatable.selectfilter';
import LabResultConfigEditDialog from '../../Dialogs/Configuration/LabResultConfigEditDialog';
import { Nav, Form } from 'react-bootstrap';
import { useBusinessUnits, useQualities } from '../../Utilities/Hooks';
import CommonConfigPage from './CommonConfigPage';
import { fetchDataAuthenticated } from '../../Utilities/HttpUtils';

function LabResultsConfigTable({ onRowClick, includeDeleted, businessUnitId, qualityId, refresh }) {

    const [loaded, setLoaded] = useState(true);
    const [errored, setErrored] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const getLabResultsConfig = async function () {
            let url = `api/v1/labresultconfig/?includeDeleted=${includeDeleted}`;
            if (businessUnitId > 0)
                url += `&businessUnitId=${businessUnitId}`;
            if (qualityId > 0)
                url += `&qualityId=${qualityId}`;

            await fetchDataAuthenticated(url)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong.');
                })
                .then(data => {
                    setTableData(data);
                    setErrored(false);
                    setLoaded(true);
                })
                .catch(error => {
                    setErrored(true);
                    setLoaded(false);
                });
        };

        getLabResultsConfig();
    }, [includeDeleted, businessUnitId, refresh]);

    const tableColumns = [
        {
            Header: 'SampleName',
            accessor: (originalRow, rowIndex) => {
                return (originalRow.sampleName === "") ? (originalRow.component) : (originalRow.sampleName);
            },
            filter: 'includesString',
            Cell: (props) => {
                return (<Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>)
            }
        },
        {
            Header: 'Quality',
            accessor: 'qualityName',
            filter: 'includesString'
        },
        {
            Header: 'Description',
            accessor: 'description',
            filter: 'includesString'
        },
        {
            Header: 'SamplingPoint',
            accessor: 'samplingPoint',
            filter: 'includesString'
        },
        {
            Header: 'UnitArea',
            accessor: 'unitArea',
            filter: 'includesString'
        },
        {
            Header: 'Analysis',
            accessor: 'analysis',
            filter: 'includesString'
        },
        {
            Header: 'Component',
            accessor: 'component',
            filter: 'includesString'
        },
        {
            Header: 'LabResultType',
            accessor: 'labResultType',
            filter: 'includesString'
        },
        {
            Header: 'Business Unit',
            accessor: 'businessUnitName',
            Filter: SelectFilter,
            filter: 'equals'
        }
    ];

    const getRowProps = (row) => {
        if (row.original.isDeleted)
            return { className: "deleted" };

        return {};
    };

    var table = DataTable({ tableColumns: tableColumns, tableData: tableData, initialState: {} , getRowProps: getRowProps });

    let tableMarkup = loaded
        ? table
        : errored ? <p>There was a problem.</p>
    : < p > <em>Loading...</em></p>;

    return tableMarkup;
}

function LabResultsConfigConfiguration() {

    const businessUnits = useBusinessUnits(false);
    const [businessUnitId, setBusinessUnitId] = useState(0);

    const qualities = useQualities(false, businessUnitId);
    const [qualityId, setQualityId] = useState(0);
    
    const getNewLabResultConfig = () => {
        return { labResultConfigId: null, qualityId: qualityId, businessUnitId: businessUnitId, isDeleted: false }
    };

    const changeBusinessUnitFilter = (e) => {
        setBusinessUnitId(e.currentTarget.value);
        setQualityId(0);
    }

    return (
        <CommonConfigPage
            title="Lab Results Config"
            table={(includeDeleted, showEditDialog, refreshList) => <LabResultsConfigTable onRowClick={showEditDialog} includeDeleted={includeDeleted} businessUnitId={businessUnitId} qualityId={qualityId} refresh={refreshList} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <LabResultConfigEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} businessUnits={businessUnits} />}
            getNewConfigObject={getNewLabResultConfig}
        >
            <Form.Select type="text" value={businessUnitId} className="filter-select float-start" onChange={changeBusinessUnitFilter}>
                <option value={0} key={0}>All Business Units</option>
                {businessUnits.map((bu) => <option value={bu.businessUnitId} key={bu.businessUnitId}>{bu.name}</option>)}
            </Form.Select>
            <Form.Select type="text" value={qualityId} className="filter-select float-start" onChange={(e) => { setQualityId(e.currentTarget.value) }}>
                <option value={0} key={0}>All Qualities</option>
                {qualities.map((q) => <option value={q.qualityId} key={q.qualityId}>{q.name}</option>)}
            </Form.Select>
        </CommonConfigPage>
    );
}

export default LabResultsConfigConfiguration;