import React, { useState, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { DraggableModalDialog } from '../DraggableModalDialog';
import CommentDialog from '../CommentDialog';
import UserContext from '../../../components/Contexts/UserContext';

function CommonConfigDialog({ children, title, dialogData, showDialog, closeDialog, save, fieldErrors, setFieldErrors, wideDialog = false }) {

    const [validated, setValidated] = useState(false);
    const [showCommentDialog, setShowCommentDialog] = useState(false);

    const userContext = useContext(UserContext);
    const permissionToConfigure = userContext.privileges.length > 0 && userContext.privileges.includes("CanConfigure");

    const handleClose = () => {
        setFieldErrors({});
        setValidated(false);
        closeDialog();
    }

    const handleSave = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            setShowCommentDialog(true);
        }

        setValidated(true);
    }

    const handleCommentSave = (comment) => {

        dialogData.comment = comment

        save(dialogData)
            .then(data => {
                setFieldErrors({});
                closeDialog();
            })
            .catch(error => {
                setFieldErrors({
                    ...fieldErrors,
                    ...error.errors
                });

                for (const field in error.errors) {
                    let fieldDOM = document.querySelector("[name=" + field + "]");
                    fieldDOM.setCustomValidity(error.errors[field]);
                }
            });
    }

    return (
        <>
            {showDialog &&
                <Modal dialogAs={DraggableModalDialog} className={wideDialog ? "wide-dialog" : ""} show={showDialog} onHide={handleClose} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={validated} onSubmit={handleSave} id="configForm">
                            <fieldset disabled={!permissionToConfigure}>
                                {children}
                            </fieldset>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" form="configForm" disabled={!permissionToConfigure}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            {showCommentDialog &&
                <CommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} onSave={handleCommentSave} />
            }
        </>
    );
}

export default CommonConfigDialog;