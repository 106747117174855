import React from 'react';
import { ToggleButton  } from 'react-bootstrap';

function CheckBoxFilter({ checked, setChecked, name }) {
    
    // Render a checkbox button
    return (
        <ToggleButton
            
            id="toggle-check"
            type="checkbox"
            checked={checked}
            value="1"
            onChange={(e) => setChecked(e.currentTarget.checked)}
            >
                {name}
        </ToggleButton>
    )
}

export default CheckBoxFilter;
