import React, { useMemo } from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from "@fortawesome/free-solid-svg-icons";

function ReasonFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering using the preFilteredRows
    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach(row => {
            if (row.values[id]) {
                options.add(row.values[id]);
            }
        })
        return [...options.values()];
    }, [id, preFilteredRows])

    const allNoDebugOptions = {
        name: "allNoDebug", value: [...new Set(preFilteredRows.map(o => o.values.reason).filter(r => r !== "Debug"))].toString() 
    }
    const anyReasonOptions = {
        name: "anyReason", value: [...new Set(preFilteredRows.map(o => o.values.reason).filter(r => r !== "" && r !== null))].toString()
    }
    const anyReasonNoDebugOptions = {
        name: "anyReasonNoDebug", value: [...new Set(preFilteredRows.map(o => o.values.reason).filter(r => r !== "" && r !== null && r !== "Debug"))].toString()
    }

    return (
        <InputGroup>
            <InputGroup.Text><FontAwesomeIcon icon={faFilter} /></InputGroup.Text>
            <Form.Select
                id="commentReasonFilter"
                size="sm"
                value={filterValue}
                onChange={e => {
                    setFilter(e.target.value)
                }}>
                <option key="0" id="all" value="">All</option>
                <option key="1" id="allNoDebug" value={JSON.stringify(allNoDebugOptions)}>All (no debug)</option>
                <option key="2" id="anyReason" value={JSON.stringify(anyReasonOptions)}>Any reason</option>
                <option key="3" id="anyReasonNoDebug" value={JSON.stringify(anyReasonNoDebugOptions)}>Any reason (no debug)</option>
                {options.map((option, i) => (

                    <option key={i + 4} value={JSON.stringify({name: option, value:option})}>
                        {option}
                    </option>
                ))}
            </Form.Select>
        </InputGroup>
    )
}

export default ReasonFilter;
