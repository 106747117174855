import React, { useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp, faAnglesLeft, faAngleLeft, faAngleRight, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import DefaultColumnFilter from './datatable.columnfilter';
import BTable from 'react-bootstrap/Table';
import { persistSortOrder, persistTableFilters, getTableFilters } from '../Utilities/SessionStorage';

// Create a default prop getter
const defaultPropGetter = () => ({})

function DataTable({ id = Math.random(), tableColumns, tableData, initialState, isServerSideTable = false, setSorting, setFilters, getRowProps = defaultPropGetter }) {

    const columns = useMemo(() => tableColumns, [tableColumns]);
    const data = useMemo(() => tableData, [tableData]);

    const {
        page,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy, filters },
    } = useTable(
        {
            columns,
            data,
            initialState: initialState,
            defaultColumn: {
                Filter: DefaultColumnFilter,
            },
            manualPagination: isServerSideTable,
            manualSortBy: isServerSideTable,
            manualFilters: isServerSideTable,
            // When these following properties are to set true, the data needs to be memoized
            autoResetFilters: false,
            autoResetGlobalFilter: false,
            autoResetHiddenColumns: false,
            autoResetSortBy: false,
            autoResetPage: true
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    useEffect(() => {
        if (isServerSideTable) {
            setSorting(sortBy);
        }
    }, [isServerSideTable, sortBy])

    useEffect(() => {
        if (isServerSideTable && id) {
            setFilters(getTableFilters(id))
        }
    }, [isServerSideTable, filters])

    function Pagination() {
        if (pageOptions.length > 1) {
            return (
                <div className="d-flex justify-content-end align-items-center">
                    <div className="me-3">
                        <Form.Select
                            id="pageSize"
                            size="sm"
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}>
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                    <span className="align-items-center me-3">Page <strong>{pageIndex + 1}</strong> of <strong>{pageOptions.length}</strong></span>
                    <div className="pagination">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            <FontAwesomeIcon icon={faAnglesLeft} />
                        </button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            <FontAwesomeIcon icon={faAnglesRight} />
                        </button>
                    </div>
                </div>
            )
        }
    }


    return (
        <>
            <BTable responsive striped bordered hover size="sm" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    <div className="disable-select" {...column.getHeaderProps([column.getSortByToggleProps(), { className: column.className }])}>
                                        <span className="float-end">
                                            {column.isSorted ? column.isSortedDesc ? <FontAwesomeIcon icon={faSortDown} /> : <FontAwesomeIcon icon={faSortUp} /> : <div className="invisible"><FontAwesomeIcon icon={faSortUp} /></div>}
                                        </span>
                                        {column.render('Header')}
                                        {id && persistSortOrder(id, sortBy)}
                                    </div>
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    {id && persistTableFilters(id, column)}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps(getRowProps(row))}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps({
                                                className: cell.column.className
                                            })}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </BTable>

            {!isServerSideTable &&
                Pagination()
            }
        </>
    )
}

export default DataTable;