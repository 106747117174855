import React, { useState } from 'react';
import CommonConfigDialog from './CommonConfigDialog';
import { DialogInput, DialogSelect, RequiredDialogSelect, DialogCheckbox } from '../../DialogInputs';
import { handleInputChange } from '../../Utilities/DialogUtils';
import { fetchDataAuthenticated } from '../../Utilities/HttpUtils';
import { useQualities, useRecipeProperties, useRecipeDataTypes } from '../../Utilities/Hooks';
import { sortByPropertyName } from '../../Utilities/SortFunctions';

function RecipeDataEditDialog({ dialogData, setDialogData, showDialog, closeDialog, businessUnitId }) {

    const [fieldErrors, setFieldErrors] = useState({});
    const qualities = useQualities(false, businessUnitId);
    const properties = useRecipeProperties(false, businessUnitId);
    const recipeDataTypes = useRecipeDataTypes(false, businessUnitId);

    const saveRecipeData = (recipeData) => {

        // Allow reset of recipe data type
        if (recipeData.recipeDataTypeId === "") {
            recipeData.recipeDataTypeId = null;
        }

        let promise = new Promise((resolve, reject) => {
            let url = `api/v1/recipedata`;
            if (recipeData.recipeDataId != null) {
                url += `/${recipeData.recipeDataId}`
            }

            fetchDataAuthenticated(url, "POST", JSON.stringify(recipeData))
                .then(response => response.json())
                .then(data => {
                    if (data.errors !== null && data.errors !== undefined) {
                        reject(data);
                    }
                    resolve(data);
                })
                .catch(error => {
                    alert("There was a problem! " + error);
                });

        });

        return promise;
    };

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    return (
        <CommonConfigDialog title={"Recipe " + dialogData.recipeName + " - Data"} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveRecipeData} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors}>
            <DialogSelect controlId="formRecipeDataQuality" title="Quality" name="qualityId" value={dialogData.qualityId} error={fieldErrors.qualityId} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {qualities
                    .sort(sortByPropertyName("name"))
                    .map((q) => <option value={q.qualityId} key={q.qualityId}>{q.name}</option>)
                }
            </DialogSelect>
            <RequiredDialogSelect controlId="formRecipeDataProperty" title="Property" name="recipePropertyId" value={dialogData.recipePropertyId} error={fieldErrors.recipePropertyId} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {properties
                    .sort(sortByPropertyName("name"))
                    .map((p) => <option value={p.recipePropertyId} key={p.recipePropertyId}>{p.name}</option>)
                }
            </RequiredDialogSelect>
            <DialogSelect controlId="formRecipeDataType" title="Recipe Data Type" name="recipeDataTypeId" value={dialogData.recipeDataTypeId} error={fieldErrors.recipeDataTypeId} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {recipeDataTypes
                    .sort(sortByPropertyName("name"))
                    .map((t) => <option value={t.recipeDataTypeId} key={t.recipeDataTypeId}>{t.name}</option>)
                }
            </DialogSelect>
            <DialogInput controlId="formRecipeDataUnits" title="Units" name="units" value={dialogData.units} error={fieldErrors.units} onChange={onChange} />
            <DialogInput controlId="formRecipeDataDecimalPlaces" title="Decimal Places" name="decimalPlaces" value={dialogData.decimalPlaces} error={fieldErrors.decimalPlaces} onChange={onChange} />
            <DialogInput controlId="formRecipeDataMin" title="Min Value" name="minValue" value={dialogData.minValue} error={fieldErrors.minValue} onChange={onChange} />
            <DialogInput controlId="formRecipeDataTarget" title="Target Value" name="targetValue" value={dialogData.targetValue} error={fieldErrors.targetValue} onChange={onChange} />
            <DialogInput controlId="formRecipeDataMax" title="Max Value" name="maxValue" value={dialogData.maxValue} error={fieldErrors.maxValue} onChange={onChange} />
            <DialogInput controlId="formRecipeDataDisplayIndex" title="Display Index" name="displayIndex" value={dialogData.displayIndex} error={fieldErrors.displayIndex} onChange={onChange} />
            <DialogCheckbox disabled={dialogData.recipeDataId === null} controlId="formRecipeDataIsDeleted" title="Deleted?" name="isDeleted" checked={dialogData.isDeleted} error={fieldErrors.isDeleted} onChange={onChange} />
        </CommonConfigDialog>
    );
}

export default RecipeDataEditDialog;