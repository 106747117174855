import React, { useState, useEffect, useContext } from 'react';
import DataTable from '../../DataTable/datatable.component';
import { useTranslation } from 'react-i18next';
import { Button, ToggleButton, Modal, Accordion, Row, Col, Container, Nav, Form } from 'react-bootstrap';
import { DraggableModalDialog } from '../DraggableModalDialog';
import { handleInputChange } from '../../Utilities/DialogUtils';
import { useRecipeTypes, useEquipment } from '../../Utilities/Hooks';
import { fetchDataAuthenticated } from '../../Utilities/HttpUtils';
import CommentDialog from '../CommentDialog';
import RecipeDataEditDialog from './RecipeDataEditDialog';
import { RequiredSummaryInput, SummaryInput, RequiredSummarySelect, SummarySelect, SummaryCheckbox } from '../../DialogInputs';
import { DateToLocaleString, longDateAndTimeFormat } from '../../Utilities/DateUtils';
import { sortByPropertyName } from '../../Utilities/SortFunctions';
import UserContext from '../../../components/Contexts/UserContext';

function RecipeSummaryArea({ recipe, setRecipe, businessUnits }) {

    const [validated, setValidated] = useState(true);
    const [fieldErrors, setFieldErrors] = useState({});
    const [showCommentDialog, setShowCommentDialog] = useState(false);
    const recipeTypes = useRecipeTypes(false, recipe.businessUnitId);
    const equipment = useEquipment(false, recipe.businessUnitId);

    const userContext = useContext(UserContext);
    const permissionToConfigure = userContext.privileges.length > 0 && userContext.privileges.includes("CanConfigure");

    const handleSave = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            setShowCommentDialog(true);
        }

        setValidated(true);
    };

    const handleCommentSave = (comment) => {

        recipe.comment = comment

        saveRecipe(recipe)
            .then(data => {
                alert("Recipe saved successfully");
                setFieldErrors({});
                setRecipe(data);
            })
            .catch(error => {
                setFieldErrors({
                    ...fieldErrors,
                    ...error.errors
                });

                for (const field in error.errors) {
                    let fieldDOM = document.querySelector("[name=" + field + "]");
                    fieldDOM.setCustomValidity(error.errors[field]);
                }
            });
    }

    const saveRecipe = (recipe) => {

        let promise = new Promise((resolve, reject) => {
            let url = `api/v1/recipe`;
            if (recipe.recipeId != null) {
                url += `/${recipe.recipeId}`
            }

            fetchDataAuthenticated(url, "POST", JSON.stringify(recipe))
                .then(response => response.json())
                .then(data => {
                    if (data.errors !== null && data.errors !== undefined) {
                        reject(data);
                    }
                    resolve(data);
                })
                .catch(error => {
                    alert("There was a problem! " + error);
                });

        });

        return promise;
    };

    const onChange = (e) => {
        handleInputChange(e, recipe, setRecipe, fieldErrors, setFieldErrors);
    }

    return (
        <>
            <Container>
                <Row>
                    <Form noValidate validated={validated} onSubmit={handleSave} id="recipeSummaryForm">
                        <fieldset disabled={!permissionToConfigure}>
                            <Form.Group as={Row} className="mb-1">
                                <RequiredSummaryInput title="Name" name="name" value={recipe.name} error={fieldErrors.name} onChange={onChange} />
                                <RequiredSummarySelect title="Business Unit" name="businessUnitId" value={recipe.businessUnitId} error={fieldErrors.businessUnitId} onChange={onChange}>
                                    <option value="" key={0} >Please Select...</option>
                                    {businessUnits
                                        .sort(sortByPropertyName("name"))
                                        .map((bu) => <option value={bu.businessUnitId} key={bu.businessUnitId}>{bu.name}</option>)
                                    }
                                </RequiredSummarySelect>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                                <RequiredSummarySelect title="Recipe Type" name="recipeTypeId" value={recipe.recipeTypeId} error={fieldErrors.recipeTypeId} onChange={onChange}>
                                    <option value="" key={0} >Please Select...</option>
                                    {recipeTypes
                                        .sort(sortByPropertyName("name"))
                                        .map((rt) => <option value={rt.recipeTypeId} key={rt.recipeTypeId}>{rt.name}</option>)
                                    }
                                </RequiredSummarySelect>
                                <SummarySelect title="Equipment" name="equipmentId" value={recipe.equipmentId} error={fieldErrors.equipmentId} onChange={onChange}>
                                    <option value="" key={0} >Please Select...</option>
                                    {equipment
                                        .sort(sortByPropertyName("name"))
                                        .map((e) => <option value={e.equipmentId} key={e.equipmentId}>{e.name}</option>)
                                    }
                                </SummarySelect>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                                <RequiredSummaryInput title="Version" name="version" value={recipe.version} error={fieldErrors.version} onChange={onChange} />
                                <SummaryCheckbox title="Prime?" name="isPrime" checked={recipe.isPrime} error={fieldErrors.isPrime} onChange={onChange} />
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                                <SummaryCheckbox title="Pellet?" name="isPellet" checked={recipe.isPellet} error={fieldErrors.isPellet} onChange={onChange} />
                                <SummaryInput title="Addpack" name="addpack" value={recipe.addpack} error={fieldErrors.addpack} onChange={onChange} />
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                                <SummaryInput title="Reactor Grade" name="reactorGrade" value={recipe.reactorGrade} error={fieldErrors.reactorGrade} onChange={onChange} />
                                <SummaryInput title="Grade Code" name="gradeCode" value={recipe.gradeCode} error={fieldErrors.gradeCode} onChange={onChange} />
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                                <SummaryInput title="Material Code" name="materialCode" value={recipe.materialCode} error={fieldErrors.materialCode} onChange={onChange} />
                                <SummaryInput title="Material Type" name="materialType" value={recipe.materialType} error={fieldErrors.materialType} onChange={onChange} />
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm={2}>Last Change Account</Form.Label>
                                <Col sm={4}>
                                    <Form.Control disabled type="text" value={recipe.lastChangeAccount} />
                                </Col>
                                <Form.Label column sm={2}>Last Change</Form.Label>
                                <Col sm={4}>
                                    <Form.Control disabled type="text" value={DateToLocaleString(recipe.lastChange, longDateAndTimeFormat)} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                                <SummaryCheckbox disabled={recipe.recipeId === null} title="Deleted?" name="isDeleted" checked={recipe.isDeleted} error={fieldErrors.isDeleted} onChange={onChange} />
                            </Form.Group>
                        </fieldset>
                    </Form>
                </Row>
                <Row>
                    <Col>
                        <Button variant="primary" className="float-end" type="submit" form="recipeSummaryForm" disabled={!permissionToConfigure}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Container>

            <CommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} onSave={handleCommentSave} />
        </>
    );
}

function RecipeDataTable({ onRowClick, includeDeleted, recipeId, refresh }) {

    const [loaded, setLoaded] = useState(false);
    const [errored, setErrored] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const getRecipeData = async function () {
            if (recipeId > 0) {
                let url = `api/v1/recipedata/?includeDeleted=${includeDeleted}&recipeId=${recipeId}`;

                await fetchDataAuthenticated(url)
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error('Something went wrong.');
                    })
                    .then(data => {
                        setTableData(data);
                        setErrored(false);
                        setLoaded(true);
                    })
                    .catch(error => {
                        setErrored(true);
                        setLoaded(false);
                    });
            }
        };

        getRecipeData();
    }, [includeDeleted, recipeId, refresh]);

    const tableColumns = [
        {
            Header: 'Quality',
            accessor: 'qualityName',
            filter: 'includesString'
        },
        {
            Header: 'Property',
            accessor: 'recipePropertyName',
            filter: 'includesString',
            Cell: (props) => {
                return (<Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>)
            }
        },
        {
            Header: 'Recipe Data Type',
            accessor: 'recipeDataTypeName',
            filter: 'includesString'
        },
        {
            Header: 'Units',
            accessor: 'units',
            filter: 'includesString'
        },
        {
            Header: 'Decimal Places',
            accessor: 'decimalPlaces',
            filter: 'includesString'
        },
        {
            Header: 'Min Value',
            accessor: 'minValue',
            filter: 'includesString'
        },
        {
            Header: 'Target Value',
            accessor: 'targetValue',
            filter: 'includesString'
        },
        {
            Header: 'Max Value',
            accessor: 'maxValue',
            filter: 'includesString'
        },
        {
            Header: 'Display Index',
            accessor: 'displayIndex',
            filter: 'includesString'
        }
    ];

    const getRowProps = (row) => {
        if (row.original.isDeleted)
            return { className: "deleted" };

        return {};
    };

    var table = DataTable({ tableColumns: tableColumns, tableData: tableData, initialState: { pageSize: 50 }, getRowProps: getRowProps });

    let tableMarkup = loaded
        ? table
        : errored ? <p>There was a problem.</p>
            : < p > <em>Loading...</em></p>;

    return tableMarkup;
}

function RecipeDataArea({ recipe }) {

    const [includeDeleted, setIncludeDeleted] = useState(false);
    const [refreshList, setRefreshList] = useState(0);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogData, setDialogData] = useState({ name: "" });

    const userContext = useContext(UserContext);
    const permissionToConfigure = userContext.privileges.length > 0 && userContext.privileges.includes("CanConfigure");

    const showEditDialog = (dialogData) => {
        setDialogData(dialogData);
        setShowDialog(true);
    }

    const showNewDialog = () => {
        setDialogData({ recipeDataId: null, recipeId: recipe.recipeId, recipeName: recipe.name });
        setShowDialog(true);
    }

    const closeDialog = () => {
        setShowDialog(false);
        setRefreshList(refreshList + 1);
    };

    return (
        //<CommonConfigPage
        //    title=""
        //    table={(includeDeleted, showEditDialog, refreshList) => <RecipeDataTable onRowClick={showEditDialog} includeDeleted={includeDeleted} recipeId={recipe.recipeId} refresh={refreshList} />}
        //    dialog={(dialogData, setDialogData, showDialog, closeDialog) => <RecipeDataEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} businessUnitId={recipe.businessUnitId} />}
        //    getNewConfigObject={getNewRecipeData}
        ///>
        <Container fluid="md" className="mb-3">
            <Row>
                <Col>
                    <RecipeDataTable onRowClick={showEditDialog} includeDeleted={includeDeleted} recipeId={recipe.recipeId} refresh={refreshList} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ToggleButton
                        className="mb-2 float-start"
                        id="toggle-check-deleted"
                        type="checkbox"
                        variant="outline-primary"
                        checked={includeDeleted}
                        value="1"
                        onChange={(e) => setIncludeDeleted(e.currentTarget.checked)}
                    >
                        Include Deleted
                    </ToggleButton>
                    <Button className="float-end" variant="primary" onClick={showNewDialog} disabled={!permissionToConfigure}>Add</Button>
                </Col>
            </Row>

            <RecipeDataEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} businessUnitId={recipe.businessUnitId} />

        </Container>
    );
}

function RecipeEditDialog({ dialogData, setDialogData, showDialog, closeDialog, businessUnits }) {

    const { t } = useTranslation();

    return (
        <Modal dialogAs={DraggableModalDialog} handle=".recipe-header" className="detail-dialog" show={showDialog} onHide={closeDialog} backdrop="static">
            <Modal.Header closeButton className="recipe-header">
                <Modal.Title>Recipe - {dialogData.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Accordion defaultActiveKey={['summary', 'recipeData']} alwaysOpen>
                    <Accordion.Item eventKey="summary">
                        <Accordion.Header>{t('Recipe.Summary.Title')}</Accordion.Header>
                        <Accordion.Body>
                            <RecipeSummaryArea recipe={dialogData} setRecipe={setDialogData} businessUnits={businessUnits} />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="recipeData">
                        <Accordion.Header>{t('Recipe.RecipeData.Title')}</Accordion.Header>
                        <Accordion.Body>
                            <RecipeDataArea recipe={dialogData} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeDialog}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default RecipeEditDialog;