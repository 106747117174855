import { useTranslation } from 'react-i18next';
import DataTable from '../../../DataTable/datatable.component';
import DateColumn from '../../../DataTable/datatable.datecolumn';
import SelectFilter from '../../../DataTable/datatable.selectfilter';
import NoFilter from '../../../DataTable/datatable.nofilter';

function LabResultsSection({ labResults }) {

    const { t } = useTranslation();

    const tableColumns = [
        DateColumn({ header: t('LotDetails.LabResults.SampleTime'), accessor: 'sampleTime' }),
        {
            Header: t('LotDetails.LabResults.Collection'),
            accessor: 'collection',
            Filter: SelectFilter
        },
        {
            Header: t('LotDetails.LabResults.CollectionType'),
            accessor: 'collectionType',
            Filter: SelectFilter
        },
        {
            Header: t('LotDetails.LabResults.Quality'),
            accessor: 'quality',
            Filter: SelectFilter
        },
        {
            Header: t('LotDetails.LabResults.Component'),
            accessor: 'component',
            Filter: SelectFilter
        },
        {
            Header: t('LotDetails.LabResults.SamplingPoint'),
            accessor: 'samplingPoint',
            Filter: SelectFilter
        },
        {
            Header: t('LotDetails.LabResults.SMSampleId'),
            accessor: 'smSampleId',
            filter: 'includesString'
        },
        {
            Header: t('LotDetails.LabResults.Value'),
            accessor: 'value',
            filter: 'includesString'
        },
        {
            Header: t('LotDetails.LabResults.Units'),
            accessor: 'units',
            Filter: NoFilter
        }
    ];

    const getRowProps = (row) => {
        if (row.original.isOffspec)
            return { className: "offspec" };

        return {};
    };

    const initialState = {
        filters: [
            {
                id: 'collectionType',
                value: 'L',
            },
        ],
        sortBy: [
            {
                id: 'quality',
                desc: false
            },
            {
                id: 'collection',
                desc: false
            },
            {
                id: 'sampleTime',
                desc: false
            }
        ],
        pageSize: 50
    };

    let table = DataTable({ tableColumns: tableColumns, tableData: labResults, initialState: initialState, getRowProps: getRowProps });

    return labResults.length > 0 ? table : "No data";
}

export default LabResultsSection;